var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动区域", prop: "region" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择活动区域" },
                  model: {
                    value: _vm.form.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "region", $$v)
                    },
                    expression: "form.region",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "招商银行", value: "shanghai" },
                  }),
                  _c("el-option", {
                    attrs: { label: "西安银行", value: "beijing" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }